import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        lng: 'hu',
        fallbackLng: 'hu',
        interpolation: {
            escapeValue: false,
        },
        resources: {
            hu: {
                translation: {
                    general: {
                        all_rights: "Minden jog fenntartva",
                        close: "Bezárás",
                    },
                    navigation: {
                        home: "Főoldal",
                        about: "Rólam",
                        projects: "Projektek",
                        contact: "Kapcsolat"
                    },
                    homepage: {
                        title: "Full-stack webfejlesztő",
                        name: "Tar Bence",
                        description: "PHP-ban jártas full-stack fejlesztő vagyok. Skálázható, biztonságos és megbízható webalkalmazásokban szerzett tapasztalataim hasznosítom számos keretrendszer és technológia használatával egyetemben. Szeretek komplex problémákat megoldani és új készségeket elsajátítani. Különös figyelmet fordítok a kód minőség megőrzésére, hogy megfeleljek az iparági szabványoknak. Mindig keresem az új kihívásokat és lehetőségeket a fejlesztői szakmában való fejlődésre.",
                    },
                    projects: {
                        title: "Projektek, amelyeken dolgoztam",
                        description: "Az évek alatt számos különböző projekteken dolgoztam. A legtöbb kódja nem őublikálható, ezekről egy leírás olvasható. A többi projekt elérhető GitHub-on.",
                        link_text: "Projekt megtekintése",
                        maros_wax_title: "Maros-Wax",
                        maros_wax_description: "Egy oldalas React alkalmazás egy kézi autómosóhoz. React és Tailwind használatával készítve, egy Netlify kapcsolatfelvételi űrlappal.",
                        image_converter_title: "Kép konverter",
                        image_converter_description: "React, Tailwind, JSZip használatával készült alkalmazás. Segítségével PNG és JPG formátumból WEBP-re tudjuk alakítani képeinket, ezzel jelentősen csökkentve a méretüket. Tömeges konvertálás esetén pedig ZIP-ként is le tudjuk tölteni.",
                        sms_service_title: "SMS szolgáltatás",
                        sms_service_description: "PHP 8, Laravel, Blade, MySQL, Redis, Tailwind használatával készült webalkalmazás. Az előfizetőknek lehetőségük van API végponton keresztül, JSON formátumban üzenetet küldeni az általuk megadott telefonszámra. A küldésről egy Queue rendszer gondoskodik, amely a megadott telefonszám alapján, automatikusan a legkedvezőbb árazású, saját SIM kártyáról indítja a küldést. Amennyiben a felhasználó megadott egy Callback URL-t a kérésben, az eredményről értesíti őt, szintén JSON formátumban. A rendszer rendelkezik publikus felülettel, ahol a látogatók informálódhatnak, admin felülettel, ahol a rendszer adminisztrátorai tudnak felhasználókat, belső SIM kártyákat kezelni, illetve a felhasználók statisztikákat tudnak megtekinteni.",
                        unique_webshop_title: "Egyedi webshop",
                        unique_webshop_description: "PHP 8, Slim, Twig, MySQL, Bootstrap 4, jQuery használatával készült webshop. Az autentikáció a megrendelő által biztosított API végponton keresztül történik. Minden leadott rendelést automatikusan továbbításra kerül egy raktárkezelő rendszerbe. A hagyományos email értesítések helyett egy belső levelezés található meg benne, amely a megrendelőhöz is beküldésre kerül. A termékek automatikusan szinkronizálódnak a raktárkezelővel, így mindig a valós mennyiséget mutatják a vásárlóknak.",
                        packpoint_title: "Csomagpont",
                        packpoint_description: "PHP 8, Slim, Twig, MySQL, Bootstrap 4 használatával, egy raktárkezelő helyszíni csomagponthájoz készített webalkalmazás. A raktárkezelőbe beérkező rendeléseket lehetőség van csomagpontra kérni, személyes átvétellel. Ezek API végponton keresztül bekerülnek a rendszerbe és az állapotváltozásokat vissza is küldi a raktárkezelőnek. A dolgozóknak lehetőségük van kasszát nyitni-zárni, rendeléseket kezelni, statisztikákat megtekinteni. Automatikus CSV exportok készülnek a rendelésekről, kasszákról, előzményekről. A vevők email értesítések mellett, SMS-ben is értesítéseket kapnak a csomagjuk állapotáról, amely a fenti SMS szolgáltatást használja.",
                        exhibitions_title: "Kiállítások",
                        exhibitions_description: "ReactJS, NextJS, Tailwind használatával készült egyedi, interaktív szoftverek. Lényegük, hogy a látogató tekintetét felkeltsék, ezért nagy szerepet kapott a megvalósításban a design, illetve hogy bárki számára könnyen megérthető, élvezhető játékok legyenek. Pl.: Puzzle, kártyajáték, animált képek, helyszínbemutatók.",
                    },
                    works: {
                        full_stack: "Full-stack webfejlesztő",
                        present: "Jelenleg",
                        workplaces: "Munkahelyek",
                    },
                    about: {
                        title: "Ki vagyok én?",
                        description: " Full-stack webfejlesztő vagyok, aki egyaránt szeret back és frontenden dolgozni. Szabadidőmben szeretek kirándulni, utazni, sportolni. Amikor tehetem, kint vagyok a szabadban. Szívesen próbálok ki új dolgokat, szeretem a kreativitást.",
                    },
                    contact: {
                        title: "Kapcsolat",
                        description: "Lépjen kapcsolatba velem az űrlap kitöltésével",
                        email: "Email cím",
                        email_placeholder: "email@cim.hu",
                        subject: "Tárgy",
                        subject_placeholder: "Mivel kapcsolatban szeretne üzenni?",
                        message: "Üzenet",
                        message_placeholder: "Hagyjon üzenetet...",
                        send: "Küldés",
                        thanks: "Köszönöm üzenetét!"
                    }
                }
            },
            en: {
                translation: {
                    general: {
                        all_rights: "All rights reserved",
                        close: "Close",
                    },
                    navigation: {
                        home: "Home",
                        about: "About",
                        projects: "Projects",
                        contact: "Contact"
                    },
                    homepage: {
                        title: "Full-stack web developer",
                        name: "Bence Tar",
                        description: "I am a full-stack developer with expertise in PHP. I have experience in building scalable, secure and reliable web applications using various frameworks and technologies. I enjoy solving complex problems and learning new skills. I am passionate about creating high-quality code that follows best practices and industry standards. I am always looking for new challenges and opportunities to grow as a developer.",
                    },
                    projects: {
                        title: "Projects I have worked on",
                        description: "Over the years, I have worked on numerous different projects. Most of their code is not publicly available, but descriptions can be found for them. The rest of the projects are available on GitHub.",
                        link_text: "View project",
                        maros_wax_title: "Maros-Wax",
                        maros_wax_description: "A one-page React app for a manual car wash. Built using React and Tailwind, with a Netlify contact form.",
                        image_converter_title: "Image converter",
                        image_converter_description: "An application built using React, Tailwind, and JSZip. With it, you can convert images from PNG and JPG formats to WEBP, significantly reducing their size. In the case of bulk conversion, you can also download them as a ZIP file.",
                        sms_service_title: "SMS service",
                        sms_service_description: "A web application built using PHP 8, Laravel, Blade, MySQL, Redis, and Tailwind. Subscribers have the option to send messages in JSON format to a phone number they specify via an API endpoint. Message sending is managed by a Queue system, which automatically initiates sending from their own SIM card at the most favorable pricing based on the provided phone number. If the user has provided a Callback URL in the request, they are notified of the result in JSON format. The system has a public interface where visitors can get information and an admin interface where system administrators can manage users, internal SIM cards, and view user statistics.",
                        unique_webshop_title: "Custom webshop",
                        unique_webshop_description: "A webshop created using PHP 8, Slim, Twig, MySQL, Bootstrap 4, and jQuery. Authentication is done through an API endpoint provided by the client. Every placed order is automatically forwarded to a warehouse management system. Instead of traditional email notifications, it includes an internal messaging system that is also sent to the client. The products are automatically synchronized with the warehouse management system, ensuring that customers always see real-time stock quantities.",
                        packpoint_title: "PackPoint",
                        packpoint_description: "A web application created using PHP 8, Slim, Twig, MySQL, Bootstrap 4, and designed for the on-site package point of a warehouse management system. It allows incoming orders in the warehouse management system to be requested for pickup at a package point with in-person delivery. These requests are entered into the system via an API endpoint, and status changes are sent back to the warehouse management system. Employees have the ability to open/close cash registers, manage orders, and view statistics. Automatic CSV exports are generated for orders, cash registers, and history. Customers receive notifications about the status of their packages via email and SMS, utilizing the SMS service mentioned above.",
                        exhibitions_title: "Exhibitions",
                        exhibitions_description: "Unique interactive software built using ReactJS, NextJS, and Tailwind. The focus is on capturing the visitor's attention, so design plays a significant role in implementation. The goal is to create easily understandable and enjoyable games for anyone. Examples include puzzles, card games, animated images, and location showcases.",
                    },
                    works: {
                        full_stack: "Full-stack web developer",
                        present: "Present",
                        workplaces: "Workplaces",
                    },
                    about: {
                        title: "Who am I?",
                        description: "I am a full-stack web developer who enjoys working on both the back-end and front-end. In my free time, I love hiking, traveling, and staying active through sports. Whenever possible, I'm outdoors enjoying nature. I'm always open to trying new things and have a strong appreciation for creativity.",
                    },
                    contact: {
                        title: "Contact",
                        description: "Contact me by filling in the form below",
                        email: "Email address",
                        email_placeholder: "email@example.com",
                        subject: "Subject",
                        subject_placeholder: "What would you like to message about?",
                        message: "Message",
                        message_placeholder: "Leave a message...",
                        send: "Send",
                        thanks: "Thank you for your message!"
                    }
                }
            }
        }
    });

export default i18n;