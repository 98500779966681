import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import "./styles/project.css";
import Modal from "../common/modal"

const Project = (props) => {
	const { logo, title, description, linkText, link, techIcons } = props;
	const [showModal, setShowModal] = React.useState(false);
	const maxCharacters = 200;
	let shortDescription = description;

	if (shortDescription.length > maxCharacters) {
	 	shortDescription = shortDescription.slice(0, maxCharacters) + '...';
	}

	return (
		<React.Fragment>
			<Modal showModal={showModal} setShowModal={setShowModal} title={title} description={description} />
			<div className="project cursor-pointer">
				<div className="project-container" onClick={() => setShowModal(true)}>
					<div className="project-logo">
						<img src={logo} alt="logo" />
					</div>
					<div className="project-title dark:text-white">{title}</div>
					<div className="project-description dark:text-slate-400">{shortDescription}</div>
					<div className="flex mt-2">
						{techIcons.map((techIcon, index) => (
							<div key={index} className="mx-1">
								<img src={techIcon} alt="techIcon" width="30px" />
							</div>
						))}
					</div>


					{linkText && (
						<div className="project-link">
							<div className="project-link-icon dark:text-slate-400 dark:hover:text-white">
								<FontAwesomeIcon icon={faLink} />
							</div>
							<a href={link} target="_blank" rel="noreferrer"
								className="project-link-text dark:text-slate-400 dark:hover:text-white">
								{linkText}
							</a>
						</div>
					)}

				</div>
			</div>
		</React.Fragment>
	);
};

export default Project;
