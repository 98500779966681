import React from "react";
import {faBriefcase} from "@fortawesome/free-solid-svg-icons";
import Card from "../common/card";
import "./styles/works.css";
import {useTranslation} from 'react-i18next';

const Works = () => {
    const {t} = useTranslation();

    return (
        <div className="works">
            <Card
                icon={faBriefcase}
                title={t("works.workplaces")}
                body={
                    <div className="works-body">
                        <div className="flex flex-col md:flex-row gap-12 items-center">
                            <div className="flex justify-center gap-2">
                                <img
                                    src="./logo/energyhub.jpeg"
                                    alt="zenit logo"
                                    className="object-contain w-12 h-12 rounded-full"
                                />
                                <div>
                                    <div className="font-bold dark:text-slate-400">EnergyHub Kft.</div>
                                    <div className="text-slate-500 dark:text-slate-400">{t("works.full_stack")}</div>
                                    <div className="text-slate-500 dark:text-slate-400">
                                        2023/11 - {t("works.present")}</div>
                                </div>
                            </div>
                            <div className="flex justify-center gap-2">
                                <img
                                    src="./logo/zenit.webp"
                                    alt="zenit logo"
                                    className="object-contain w-12 h-12 dark:invert"
                                />
                                <div>
                                    <div className="font-bold dark:text-slate-400">Zenit Informatikai Kft.</div>
                                    <div className="text-slate-500 dark:text-slate-400">{t("works.full_stack")}</div>
                                    <div className="text-slate-500 dark:text-slate-400">2021 - 2023/11</div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            />
        </div>
    );
};

export default Works;
