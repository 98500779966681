import React, { useState, useEffect } from "react";
import { BsMoonStars, BsSun } from "react-icons/bs";

const DarkModeSwitcher = () => {
    const [darkMode, setDarkMode] = useState(localStorage.getItem('darkMode') === 'true' || false);

    useEffect(() => {
        const isDarkMode = localStorage.getItem('darkMode') === 'true';
        setDarkMode(isDarkMode);
    }, []);

    useEffect(() => {
        document.documentElement.classList.toggle('dark', darkMode);
        localStorage.setItem('darkMode', darkMode);
    }, [darkMode]);

    const toggleDarkMode = () => {
        setDarkMode((prevMode) => !prevMode);
    };

    return (
        <div>
            <label className="relative inline-flex items-center cursor-pointer">
                <span className="mr-3 text-sm font-medium text-gray-900 dark:text-white">
                    <BsMoonStars size={18}/>
                </span>
                <input type="checkbox" className="sr-only peer"
                    checked={!darkMode}
                    onChange={toggleDarkMode}
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full
                 peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-['']
                  after:absolute after:top-[2px] after:left-[33px] after:bg-white after:border-gray-300 after:border
                   after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600
                    peer-checked:bg-blue-600"></div>
                <span className="ml-3 text-sm font-medium text-gray-900 dark:text-white">
                    <BsSun size={18} />
                </span>
            </label>
        </div>
    );
};

export default DarkModeSwitcher;
