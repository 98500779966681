import React from "react";
import { Link } from "react-router-dom";
import "./styles/footer.css";
import { useTranslation } from 'react-i18next';

const Footer = () => {
	const { t } = useTranslation();

	return (
		<React.Fragment>
			<div className="footer">
				<div className="footer-links">
					<ul className="footer-nav-link-list">
						<li className="footer-nav-link-item">
							<Link to="/" className="dark:text-slate-400">{t("navigation.home")}</Link>
						</li>
						<li className="footer-nav-link-item">
							<Link to="/about" className="dark:text-slate-400">{t("navigation.about")}</Link>
						</li>
						<li className="footer-nav-link-item">
							<Link to="/projects" className="dark:text-slate-400">{t("navigation.projects")}</Link>
						</li>
						<li className="footer-nav-link-item">
							<Link to="/contact" className="dark:text-slate-400">{t("navigation.contact")}</Link>
						</li>
					</ul>
				</div>

				<div className="footer-credits">
					<div className="footer-credits-text dark:text-slate-400">
						© 2023 tarbence.hu {t("general.all_rights")}.
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Footer;
