import React from "react";
import Project from "./project";
import INFO from "../../data/user";
import "./styles/allProjects.css";
import { useTranslation } from 'react-i18next';

const AllProjects = () => {
	const { t } = useTranslation();


	return (
		<div className="all-projects-container">
			{INFO.projects.map((project, index) => (
				<div className="all-projects-project" key={index}>
					<Project
						logo={project.logo}
						title={t(project.title)}
						description={t(project.description)}
						linkText={t(project.linkText)}
						link={project.link}
						techIcons={project.techIcons}
					/>
				</div>
			))}
		</div>
	);
};

export default AllProjects;
