const INFO = {
	main: {
		title: "Bence Tar - portfolio",
		name: "Bence Tar",
		email: "tarbence1@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		github: "https://github.com/tarbence1",
		linkedin: "https://www.linkedin.com/in/bence-tar-40973a209/",
		instagram: "https://www.instagram.com/bence_tar_/",
	},

	projects: [
		{
			title: "projects.maros_wax_title",
			description:
				"projects.maros_wax_description",
			logo: "./maros-wax.png",
			linkText: "projects.link_text",
			link: "https://github.com/tarbence1/react-car-wash",
			techIcons: [
				"./logo/react.png",
				"./logo/typescript.png",
				"./logo/tailwind.png",
				"./logo/netlify.png"
			]
		},
		{
			title: "projects.image_converter_title",
			description:
				"projects.image_converter_description",
			logo: "./logo/webp.png",
			linkText: "projects.link_text",
			link: "https://github.com/tarbence1/react-image-converter",
			techIcons: [
				"./logo/react.png",
				"./logo/typescript.png",
				"./logo/tailwind.png",
				"./logo/netlify.png"
			]
		},
		{
			title: "projects.sms_service_title",
			description:
				"projects.sms_service_description",
			logo: "./logo/Z.png",
			techIcons: [
				"./logo/php.png",
				"./logo/laravel.png",
				"./logo/docker.png",
				"./logo/tailwind.png",
			]
		},
		{
			title: "projects.unique_webshop_title",
			description:
				"projects.unique_webshop_description",
			logo: "./logo/Z.png",
			techIcons: [
				"./logo/php.png",
				"./logo/docker.png",
			]
		},
		{
			title: "projects.packpoint_title",
			description:
				"projects.packpoint_description",
			logo: "./logo/Z.png",
			techIcons: [
				"./logo/php.png",
				"./logo/docker.png",
			]
		},
		{
			title: "projects.exhibitions_title",
			description:
				"projects.exhibitions_description",
			logo: "./logo/Z.png",
			techIcons: [
				"./logo/react.png",
				"./logo/tailwind.png",
			]
		},
	],
};

export default INFO;
