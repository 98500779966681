import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();
    const [isChecked, setIsChecked] = useState(i18n.language === 'hu');

    const handleChangeLanguage = () => {
        if (isChecked) {
            i18n.changeLanguage('en');
        } else {
            i18n.changeLanguage('hu');
        }
        setIsChecked(!isChecked);
    };

    return (
        <div>
            <label className="relative inline-flex items-center cursor-pointer">
                <span className="mr-3 text-sm font-medium text-gray-900">
                    <img src="./icon/en.png" alt="english" width={18} height={18} />
                </span>
                <input type="checkbox" className="sr-only peer"
                    checked={isChecked}
                    onChange={handleChangeLanguage}
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full
                 peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-['']
                  after:absolute after:top-[2px] after:left-[33px] after:bg-white after:border-gray-300 after:border
                   after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600
                    peer-checked:bg-blue-600"></div>
                <span className="ml-3 text-sm font-medium text-gray-900">
                    <img src="./icon/hu.png" alt="english" width={18} height={18} />
                </span>
            </label>
        </div>
    );
};

export default LanguageSwitcher;
