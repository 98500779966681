import React from "react";
import { Link } from "react-router-dom";

import "./styles/navBar.css";
import LanguageSwitcher from "../switcher/languageSwitcher";
import DarkModeSwitcher from "../switcher/darkModeSwitcher";
import { useTranslation } from 'react-i18next';

const NavBar = (props) => {
	const { t } = useTranslation();
	const { active } = props;

	return (
		<React.Fragment>
			<div className="content-wrapper pt-8 flex justify-between switcher">
				<DarkModeSwitcher />
				<LanguageSwitcher />
			</div>
			<div className="nav-container">
				<nav className="navbar">
					<div className="nav-background dark:bg-slate-700">
						<ul className="nav-list mx-8">
							<li
								className={
									active === "home"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link to="/" className="dark:text-slate-400">{t("navigation.home")}</Link>
							</li>
							<li
								className={
									active === "about"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link to="/about" className="dark:text-slate-400">{t("navigation.about")}</Link>
							</li>
							<li
								className={
									active === "projects"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link to="/projects" className="dark:text-slate-400">{t("navigation.projects")}</Link>
							</li>
							<li
								className={
									active === "contact"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link to="/contact" className="dark:text-slate-400">{t("navigation.contact")}</Link>
							</li>
						</ul>
					</div>
				</nav>
			</div>
		</React.Fragment>
	);
};

export default NavBar;
