import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Socials from "../components/about/socials";
import INFO from "../data/user";
import "./styles/contact.css";
import { useTranslation } from 'react-i18next';

const Contact = () => {
	const { t } = useTranslation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [formSubmitted, setFormSubmitted] = useState(false);

	const handleSubmit = async (event) => {
		event.preventDefault();

		const myForm = event.target;
		const formData = new FormData(myForm);

		try {
			await fetch("/", {
				method: "POST",
				headers: { "Content-Type": "application/x-www-form-urlencoded" },
				body: new URLSearchParams(formData).toString(),
			});
			setFormSubmitted(true);
			myForm.reset();

		} catch (error) {
			console.log(error)
		}
	};

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Contact | ${INFO.main.title}`}</title>INFOclass
			</Helmet>

			<div className="page-content dark:bg-slate-800 min-h-screen">
				<NavBar active="contact" />
				<div className="content-wrapper">

					<div className="contact-container">
						<div className="title contact-title dark:text-white">
							{t("contact.title")}
						</div>

						<div className="subtitle contact-subtitle dark:text-slate-400">
							{t("contact.description")}
						</div>
					</div>

					<div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
						<form className="space-y-8"
							data-netlify="true"
							name="contact"
							method="POST"
							data-netlify-honeypot="bot-field"
							onSubmit={handleSubmit}>
							<input type="hidden" name="form-name" value="contact" />
							<div>
								<label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">{t("contact.email")}</label>
								<input type="email" id="email" name="email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg
									 focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600
									  dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500
									   dark:shadow-sm-light" placeholder={t("contact.email_placeholder")} required />
							</div>
							<div>
								<label htmlFor="subject" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">{t("contact.subject")}</label>
								<input type="text" id="subject" name="subject" className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border
									 border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600
									  dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
									placeholder={t("contact.subject_placeholder")} required />
							</div>
							<div className="sm:col-span-2">
								<label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">{t("contact.message")}</label>
								<textarea id="message" name="message" rows="6" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border
									 border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600
									  dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
									placeholder={t("contact.message_placeholder")} required></textarea>
							</div>
							{formSubmitted && (
								<p className="text-green-500 text-center">{t("contact.thanks")}</p>
							)}
							<div className="flex justify-center">
								<button
									type="submit"
									className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md
									 text-gray-700 bg-white dark:bg-slate-700 dark:text-slate-300 dark:border-gray-600"
								>
									{t("contact.send")}
								</button>
							</div>
						</form>
					</div>

					<div className="socials-container">
						<div className="contact-socials">
							<Socials />
						</div>
					</div>

					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Contact;
