import React from "react";
import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faGithub,
	faInstagram,
	faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import Footer from "../components/common/footer";
import NavBar from "../components/common/navBar";
import Works from "../components/homepage/works";
import AllProjects from "../components/projects/allProjects";
import INFO from "../data/user";
import "./styles/homepage.css";
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

const Homepage = () => {
	const { t } = useTranslation();

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Home | ${INFO.main.title}`}</title>
			</Helmet>

			<div className="page-content dark:bg-slate-800">
				<NavBar active="home" />
				<div className="content-wrapper">

					<div className="homepage-container lg:mt-32 mt-8">
						<div className="homepage-first-area">
							<div className="homepage-first-area-left-side mt-48 lg:mt-0">
								<h1 className="text-6xl title mb-2 dark:text-white">{t("homepage.name")}</h1>
								<div className="small-title homepage-title dark:text-white">
									{t("homepage.title")}
								</div>

								<div className="subtitle homepage-subtitle dark:text-slate-400">
									{t("homepage.description")}
								</div>
							</div>

							<div className="homepage-first-area-right-side">
								<div className="homepage-image-container">
									<div className="homepage-image-wrapper">
										<img
											src="profile.webp"
											alt="about"
											className="homepage-image"
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="homepage-socials">
							<a
								href={INFO.socials.github}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faGithub}
									className="homepage-social-icon dark:text-slate-400 dark:hover:text-white"
								/>
							</a>
							<a
								href={INFO.socials.linkedin}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faLinkedin}
									className="homepage-social-icon dark:text-slate-400 dark:hover:text-white"
								/>
							</a>
							<a
								href={INFO.socials.instagram}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faInstagram}
									className="homepage-social-icon dark:text-slate-400 dark:hover:text-white"
								/>
							</a>
							<a
								href={`mailto:${INFO.main.email}`}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faMailBulk}
									className="homepage-social-icon dark:text-slate-400 dark:hover:text-white"
								/>
							</a>
						</div>

						<div className="homepage-projects">
							<AllProjects />
						</div>

						<div className="homepage-after-title">

							<div className="homepage-works">
								<Works />
							</div>
						</div>

						<div className="page-footer">
							<Footer />
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Homepage;
