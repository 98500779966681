import React from "react";
import { useTranslation } from 'react-i18next';

const Modal = (props) => {
  const { showModal, setShowModal, title, description } = props;
  const { t } = useTranslation();
  return (
    <>
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[1001] outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white dark:bg-slate-800 outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid dark:border-slate-700 rounded-t">
                  <h3 className="text-3xl font-semibold text-black dark:text-white">
                    {t(title)}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black dark:text-white h-6 w-6 text-2xl block outline-none focus:outline-none">
                      x
                    </span>
                  </button>
                </div>
                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-lg leading-relaxed dark:text-slate-400">
                  {t(description)}
                  </p>
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid dark:border-slate-700 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                     {t("general.close")}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-[1000] bg-black"></div>
        </>
      ) : null}
    </>
  );
}

export default Modal;